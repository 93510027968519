<template>
    <DropdownScreen
        class="dropdown-schedule"
        relation="header-schedule"
    >
        <div class="col-span-12 md:block md:col-span-3 lg:mt-20 the-header-about__left"></div>
        <div class="col-span-12 md:col-span-6 md:grid-cols-9 mb-[7.5rem] md:mb-0 lg:mt-20 the-header-about__right">
            <div
                class="text-black text-center text-uppercase font-medium text-fluid-h1 mb-[32px]"
            >
                ГРАФИК РАБОТЫ В ПРАЗДНИКИ
            </div>
            <div class="dropdown-schedule__sub mb-[24px]">
                <p class="mb-[16px]">Уважаемые покупатели!</p>
                <p>В майские праздники мы работаем по следующим графикам:</p>
            </div>
            <div
                class="dropdown-schedule__text text-pale-sky-100 text-body-small"
            >
                <!-- Архангельск -->
                <template v-if="city === 42">
                    <p>Суббота 27 апреля - не сокращённый день, работаем в обычном режиме.</p>
                    <p>28.04, 29.04, 30.04 и 01.05 – выходные дни;</p>
                    <p>2.05 и 3.05 – работаем в обычном режиме;</p>
                    <p>4.05 и 5.05 – выходные дни;</p>
                    <p>6.05, 7.05 – рабочие дни;</p>
                    <p>08.05 – сокращённый на 1 час рабочий день;</p>
                    <p>9.05, 10.05, 11.05, 12.05 – выходные дни;</p>
                    <p>С 13.05 работаем в обычном режиме.</p>
                </template>

                <!-- Казань -->
                <template v-else-if="city === 8">
                    <p>Суббота 27 апреля - не сокращённый день, работаем в обычном режиме.</p>
                    <p>28.04, 29.04, 30.04 и 01.05 – выходные дни;</p>
                    <p>2.05 и 3.05 – работаем в обычном режиме;</p>
                    <p>4.05 и 5.05 – выходные дни;</p>
                    <p>6.05, 7.05 – рабочие дни;</p>
                    <p>08.05 – сокращённый на 1 час рабочий день;</p>
                    <p>9.05, 10.05, 11.05, 12.05 – выходные дни;</p>
                    <p>С 13.05 работаем в обычном режиме</p>
                </template>

                <!-- Калининград -->
                <template v-else-if="city === 64">
                    <p>27.04.24 - сокращённый день до 18:00;</p>
                    <p>28.04, 29.04, 30.04 - работаем 11:00-17:00; </p>
                    <p>1.05, 5.05, 9.05 - выходные дни;</p>
                    <p>2.05, 3.05 - работаем 9:00-19:00;</p>
                    <p>4.05, 10.05, 11.05, 12.05 - работаем 11:00-17:00.</p>
                </template>
                    
            </div>
        </div>
    </DropdownScreen>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import store from '~/store/base'
import DropdownScreen from '~vue/dropdown-wrappers/DropdownScreen.vue'


const city = ref<number | null>(null)
const country = ref<number | null>(null)
const isH24 = computed(() => {
    const { hostname } = new URL(window.location.href)
    return hostname.includes('home24')
})

watch(store.refs.currentCity, () => {
    const { currentCity } = store.refs

    city.value = Number(currentCity.value?.id)
    country.value = Number(currentCity.value?.relationships.country.data.id)
})
</script>

<style lang="scss" scoped>
.dropdown-schedule {
    &__sub {
        @apply text-center text-black font-medium;
        font-size: 24px;
        line-height: 32px;
    }

    &__bold {
        @apply text-black text-body font-medium mt-10;
    }
}
</style>
