<template>
    <DropdownScreenModal relation="first-login" :absolute="false" classModifier="dropdown-screen--opened"
        :preventClosingOthers="true">
        <div
            class="min-w-[312px] max-w-[500px] bg-white bg-contain bg-no-repeat bg-bottom sm:bg-right px-6 py-7 rounded-2xl sm:p-8 flex flex-col items-center gap-3">
            <h3 class="tracking-[2px] font-medium text-center text-h2 text-black uppercase leading-10">
                Представьтесь пожалуйста
            </h3>
            <div class="text-pale-sky-100 text-center">
                До конца регистрации остался всего 1 шаг. Укажите ваши
                контактные данные, чтобы мы могли к вам правильно обратиться и
                поздравить с днем рождения!
            </div>
            <button type="submit" class="button button--black" @click="redirectToPersonal">
                Хорошо
            </button>
        </div>
    </DropdownScreenModal>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import DropdownScreenModal from "~vue/dropdown-wrappers/DropdownScreenModal.vue"

export default defineComponent({
    name: "PersonalAreaFirstLoginPopup",
    components: {
        DropdownScreenModal,
    },
    methods: {
        redirectToPersonal(): void {
            window.location.href = "/lk/profile/"
        },
    },
})
</script>
