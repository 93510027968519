import {Banner, BannerTypesEnum, ImageSourceAttributes} from "~vue/banner/types";

export const getSourceParamsForBanner = (
    banner: Banner,
): ImageSourceAttributes | null => {
    const image = banner.images.small

    if (!image) {
        return null
    }

    switch (banner.type) {
        case BannerTypesEnum.MAIN_PAGE_FULL:
        case BannerTypesEnum.MAIN_PAGE_RIGHT:
        case BannerTypesEnum.MAIN_PAGE_LEFT:
            return {
                type: image.mime,
                media: `(max-width: 959px)`,
                srcset: image.src,
            }

        case BannerTypesEnum.HEADER:
            return {
                type: image.mime,
                media: `(max-width: 639px)`,
                srcset: image.src,
            }

        case BannerTypesEnum.MENU_BOTTOM:
        case BannerTypesEnum.MENU_RIGHT:
            return {
                type: image.mime,
                media: `(max-width: 1199px)`,
                srcset: image.src,
            }

        default:
            return null
    }
}
