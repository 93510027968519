import { fetchRequest } from '~/helpers/fetch'
import { JsonApiSingle } from '~/interfaces/json-api'

const relatedList = [
    'static-request-modals-corp',
    'static-request-modals-design',
    'static-request-modals-tenders'
]

const _eventForceFormsUpdate: Event = new Event('forceUpdateForms', {
    bubbles: true,
    cancelable: true,
    composed: false
})

window.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('dropdownOpen', handleDropdownScreenOpen)
})

const handleDropdownScreenOpen = (e: Event) => {
    const eventTarget = e.target as HTMLElement

    if (!eventTarget || !eventTarget.dataset.relation) return void 0

    const relatedListId = relatedList.indexOf(eventTarget.dataset.relation)

    if (relatedListId >= 0) {
        loadContent(eventTarget, eventTarget.dataset.relation)

        relatedList.splice(relatedListId, 1)

        if (!relatedList.length) window.removeEventListener('dropdownOpen', handleDropdownScreenOpen)
    }
}

const loadContent = async (target: HTMLElement, type: string) => {
    try {
        const response = await fetchRequest(`?ajax=1&controller=cms&action=getStaticRequestModals&type=${type}&id_cms=1`, {
            method: 'GET'
        })

        processResponse(target, response)
    } catch (error) {
        console.error(error)
    }
}

const processResponse = (target: HTMLElement, response: JsonApiSingle) => {
    const container = target.querySelector('.dropdown-screen__container')

    if (!container) return void 0

    container.innerHTML += response.data.attributes.html as string
    container.querySelector('.the-loader')?.classList.add('hidden')

    target.dispatchEvent(_eventForceFormsUpdate)
}

