import KeenSliderHelper from "~/classes/keen-slider-helper"
import { KeenSliderInstance } from "keen-slider"
import { scrollElement } from '~/helpers/scroll'
import { infoAccordion } from '~/client/vanilla-components/detail/info-accordion'
import { commonXHRHeaders } from "~/helpers/common-xhr-headers"

let thumbnailsContainer: HTMLElement | null = null
let prevThumbnail: HTMLElement | null = null

const processingOfGoodsIncluded = () => {
    const block = document.getElementById(
        "product-tovary-v-komplekte"
    ) as HTMLDivElement

    if (!block) {
        return void 0
    }

    block.addEventListener("click", async (e: Event) => {
        const groupsElement = document.querySelector(
                ".dropdown-screen__goods-included"
            ),
            target = e.target as HTMLElement

        let cardBlock: HTMLElement | null

        if (!groupsElement) {
            return void 0
        }

        groupsElement.innerHTML = ""

        if (target.classList.contains("tabs-card-block__item")) {
            cardBlock = target
        } else if (target.closest(".tabs-card-block__item")) {
            cardBlock = target.closest(".tabs-card-block__item")
        } else {
            return void 0
        }

        if (!cardBlock) {
            return void 0
        }

        let path = cardBlock.dataset.link

        if (path) {
            path = path.replace('/sales', '')
        }

        const currentProtocol = window.location.protocol,
            currentDomain = window.location.hostname,
            fullUrl = `${currentProtocol}//${currentDomain}${path}?loading-slider-with-additional-blocks=1`

        try {
            const content = await fetch(`${fullUrl}`, { headers: commonXHRHeaders }).then((response) => {
                if (!response.ok) {
                    throw new Error(`Network error: ${response.status}`)
                }

                return response.text()
            })

            const parser = new DOMParser(),
                doc = parser.parseFromString(content, "text/html"),
                desiredBlock = doc.querySelector(".ajax-goods-included")

            if (!desiredBlock) return void 0

            groupsElement.innerHTML = desiredBlock.innerHTML

            setTimeout(() => {
                initGallerySliderGoodsIncluded("slider-gallery-goods-included")
                initThumbnailsGoodsIncludedSlider("product-gallery-thumbnails-goodsIncluded")
                infoAccordion('.button-load--desktop', 'product__info--show-all-desktop', '.product__info-goods-included')
                infoAccordion('.button-load--mobile', 'product__info--show-all-mobile', '.product__info-goods-included')
            }, 500)

            initThumbnails()
        } catch (error) {
            console.error(error)
        }
    })
}

const initThumbnailsGoodsIncludedSlider = function (selector: string) {
    let ThumbnailsGoodsIncludedSlider: KeenSliderHelper | undefined = undefined

    const gallerySliderElement = document.getElementById(
        selector
    ) as HTMLElement

    if (!gallerySliderElement) return void 0

    ThumbnailsGoodsIncludedSlider = new KeenSliderHelper(gallerySliderElement, {
        initial: 0,
        loop: true,
        slides: {
            perView: 1,
            spacing: 8,
        },
        breakpoints: {
            "(min-width: 960px)": {
                disabled: false,
                drag: true,
            },
        },
    })

    ThumbnailsGoodsIncludedSlider.createAdditionalMarkup({
        arrows: {
            enable: true,
            html: '<svg><use href="#icon-arrow-gallery"></use></svg>',
        },
    })

    ThumbnailsGoodsIncludedSlider.instance.on("animationEnded", gallerySlideChangeCallback)


    return ThumbnailsGoodsIncludedSlider
}

const initGallerySliderGoodsIncluded = function (selector: string) {
    let gallerySlider: KeenSliderHelper | undefined = undefined

    gallerySlider = initGallerySliderCustom(selector, gallerySlider)

    const verticalSlider = document.getElementById(
        "product-gallery-thumbnails-goodsIncluded"
    )

    if (!verticalSlider) {
        return void 0
    }

    verticalSlider.addEventListener("click", (e: Event) => {
        const target = e.target as HTMLElement

        if (target.nodeName != "IMG") {
            return void 0
        }

        moveToSelectedSlide(e, gallerySlider)
    })
}

const moveToSelectedSlide = (event: Event, slider: KeenSliderHelper | undefined) => {
    if (!slider) return void 0

    const element = event.target as HTMLElement
    const slideIndex = element.dataset.slide ? Number(element.dataset.slide) : 0

    slider.instance.moveToIdx(slideIndex)
}

const initGallerySliderCustom = (
    selector: string,
    gallerySlider: KeenSliderHelper | undefined = undefined
) => {
    const gallerySliderElement = document.getElementById(
        selector
    ) as HTMLElement

    if (!gallerySliderElement) return void 0

    gallerySlider = new KeenSliderHelper(gallerySliderElement, {
        initial: 0,
        loop: true,
        slides: {
            perView: 1,
            spacing: 8,
        },
        breakpoints: {
            "(min-width: 960px)": {
                disabled: false,
                drag: true,
            },
        },
    })

    gallerySlider.createAdditionalMarkup({
        arrows: {
            enable: true,
            html: '<svg><use href="#icon-arrow-gallery"></use></svg>',
        },
    })

    gallerySlider.instance.on("animationEnded", gallerySlideChangeCallback)

    return gallerySlider
}

const gallerySlideChangeCallback = async (
    sliderInstance: KeenSliderInstance
) => {
    const slideId = sliderInstance.track.details.rel
    selectThumbnails(slideId)
    scrollToSlide(slideId)
}

const initThumbnails = () => {
    thumbnailsContainer = document.getElementById('product-gallery-thumbnails-goodsIncluded')

    if (thumbnailsContainer) prevThumbnail = thumbnailsContainer.querySelector<HTMLElement>('img:not(opacity-40)')
}

const selectThumbnails = (id: number) => {
    const thumbnail = thumbnailsContainer?.querySelector<HTMLElement>(`img[data-slide="${id}"]`)

    if (!thumbnail) return void 0

    prevThumbnail?.classList.remove('shadow-thumbnail')

    thumbnail.classList.add('shadow-thumbnail')

    prevThumbnail = thumbnail
}

const scrollToSlide = (id: number) => {
    if (!thumbnailsContainer) return void 0

    const slide = document.querySelector<HTMLElement>(`#${thumbnailsContainer.id} img[data-slide="${id}"]`)

    if (!slide) return void 0

    let offset = thumbnailsContainer.scrollTop
    let axis: 'Top' | 'Left' = 'Top'

    if (window.innerWidth >= 960 && window.innerWidth < 1200) {
        offset = thumbnailsContainer.scrollLeft
        axis = 'Left'
    }

    scrollElement(
        thumbnailsContainer,
        slide.parentElement as HTMLElement,
        offset,
        'smooth',
        axis
    )
}

export { processingOfGoodsIncluded }
