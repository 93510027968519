import 'keen-slider/keen-slider.min.css'

import KeenSliderHelper from '~/classes/keen-slider-helper'
import { getSvgSpriteIcon } from '~/helpers/get-svg-sprite-icon'

document.addEventListener('DOMContentLoaded', () => {
    const sliderContainers = document.querySelectorAll(`.product-card-images__slider`)

    sliderContainers.forEach((container) => {
        const slider = new KeenSliderHelper(
            container as HTMLElement,
            {
                loop: true,
            },
        )

        slider.createAdditionalMarkup({
            arrows: {
                enable: true,
                html: `<svg><use href="${getSvgSpriteIcon('icon-arrow-min')}"></use></svg>`
            }
        })
    })
})
