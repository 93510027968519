<script setup lang="ts">
import {computed} from "vue";
import {Banner, ImageSourceAttributes} from "~vue/banner/types";
import {getSourceParamsForBanner} from "~vue/banner/lib";
import AdvertPopup from "~vue/banner/ui/AdvertPopup.vue";
import {googleDataMarketingBannerClick} from "~/helpers/google-datalayer";

const props = withDefaults(
    defineProps<{
        banner: Banner
        fetchPriority?: 'high' | 'low' | 'auto'
        lazy?: boolean
        sources?: ImageSourceAttributes[]|null
        advertPopupPosition?: 'top'|'bottom'
    }>(),
    {
        fetchPriority: 'auto',
        lazy: true,
        sources: undefined,
        advertPopupPosition: 'bottom'
    },
)

const onClick = (e: Event) => {
    googleDataMarketingBannerClick(
        e.currentTarget as HTMLElement
    )
}

const _component = computed(() => (props.banner.advert ? 'aside' : 'div'))

const _source = computed<ImageSourceAttributes|null|undefined>(() =>
    getSourceParamsForBanner(props.banner),
)

const _bgImage = computed(() =>
    props.banner.images.bg?.src ? `url("${props.banner.images.bg?.src}")` : '',
)

const _classNames = computed(() => ({
    banner: true,
    [`banner--${props.banner.type}`]: true,
}))
</script>

<template>
    <component
        :is="_component"
        :class="_classNames"
    >
        <div
            :data-promotion-id="banner.id"
            :data-promotion-name="banner.promotion_name"
            class="banner__container"
            @click="onClick"
        >
            <picture>
                <source
                    v-if="_source"
                    :srcset="_source.srcset"
                    :media="_source.media"
                    :type="_source.type || undefined"
                />

                <template v-if="sources">
                    <source
                        v-for="source in sources"
                        :key="source.srcset"
                        :srcset="source.srcset"
                        :media="source.media"
                        :type="source.type || undefined"
                    />
                </template>

                <img
                    class="banner__image"
                    :src="banner.images.largexl?.src"
                    :alt="banner.images.largexl.alt || ''"
                    :fetchpriority="fetchPriority"
                    :loading="lazy ? 'lazy' : undefined"
                    :decoding="lazy ? 'async' : undefined"
                    :title="banner.title"
                    itemprop="image"
                />
            </picture>

            <a
                v-if="banner.url && banner.url !== '-'"
                :title="banner.title"
                :href="banner.url"
                class="banner__link"
            >
                {{ banner.title }}
            </a>
        </div>

        <AdvertPopup
            v-if="banner.advert"
            :data="banner.advert"
            :position="advertPopupPosition"
            class="banner__advert"
        />
    </component>
</template>

<style scoped lang="scss">
$class: 'banner';

.#{$class} {
    @apply relative
        w-full;

    &--header {
        @apply h-[50px];

        & .#{$class}__image {
            @apply h-[50px];
        }
    }

    &--menu_right {
        @apply w-fit
            h-min;

        & .#{$class}__image {
            @apply w-[320px]
                h-[480px];
        }
    }

    &--menu_bottom,
    &--menu_right {
        & .#{$class}__container {
            @apply rounded-2xl;
        }
    }

    &__container {
        background-image: v-bind(_bgImage);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @apply
            overflow-hidden
            grid
            w-full
            h-full
            place-items-center;
    }

    &__image-container {
        @apply inline-flex;

        &--promo {
            @apply relative;
        }
    }

    &__image {
        @apply object-cover
            object-center;
    }

    &__promo-code-popup {
        @apply px-3
            text-caption
            py-4
            rounded-xl
            bg-white
            shadow-popup;
    }

    &__advert {
        @apply absolute
            z-20
            top-2
            right-2;
    }

    &__promo-code-btn,
    &__link {
        @apply absolute
            z-10
            inset-0
            opacity-0;
    }
}
</style>
