<template>
    <div ref="self">
        <Teleport
            v-for="(componentItem, id) in componentsList"
            :to="componentItem.mountSelector"
            :key="id"
        >
            <component
                v-if="componentItem.allowRender"
                :is="componentItem.component"
                :triggerOpen="true"
            />
        </Teleport>
    </div>
</template>
<script lang="ts">
import { defineAsyncComponent, defineComponent, onBeforeMount } from 'vue'
import TheCartHeaderDropdown from '~vue/cart/header/TheCartHeaderDropdown.vue'
import TheCartModalsAddedProduct from '~vue/cart/modals/TheCartModalsAddedProduct.vue'
import TheHeaderIpLocate from '~vue/header/TheHeaderIpLocate.vue'
import store from '~/store/base'
import TheHeaderSchedule from '~vue/header/TheHeaderSchedule.vue'
import { City, CommonLinksList } from '~/interfaces/cities'
import { commonXHRHeaders } from '~/helpers/common-xhr-headers'

interface ComponentProperties {
    component: string
    mountSelector: string
    mountEvent?: string
    allowRender: boolean
    async: boolean
    relation: string
}

const TheHeaderAboutAsync = defineAsyncComponent(() => import('~vue/header/TheHeaderAbout.vue'))
const TheHeaderSearchAsync = defineAsyncComponent(() => import('~vue/header/TheHeaderSearch.vue'))
const TheHeaderCallbackAsync = defineAsyncComponent(() => import('~vue/header/TheHeaderCallback.vue'))
const TheHeaderProfileAsync = defineAsyncComponent(() => import('~vue/header/TheHeaderProfile.vue'))
const TheHeaderCheckPrintAsync = defineAsyncComponent(() => import('~vue/header/TheHeaderCheckPrint.vue'))
const TheHeaderCitySelect = defineAsyncComponent(() => import('~vue/header/TheHeaderCitySelect.vue'))

export default defineComponent({
    name: "TheHeaderHandler",
    components: {
        TheHeaderCitySelect,
        TheHeaderIpLocate,
        TheHeaderAboutAsync,
        TheHeaderProfileAsync,
        TheHeaderSearchAsync,
        TheHeaderCallbackAsync,
        TheHeaderCheckPrintAsync,
        TheCartModalsAddedProduct,
        TheCartHeaderDropdown,
        TheHeaderSchedule
    },
    data() {
        return {
            componentsList: [
                {
                    component: 'TheHeaderCitySelect',
                    mountSelector: '#header-city-select',
                    mountEvent: 'lazy-mount:city-select',
                    allowRender: false,
                    async: true,
                    relation: 'city-select'
                },
                {
                    component: 'TheHeaderIpLocate',
                    mountSelector: '#header-ip-locate',
                    allowRender: false,
                    async: false,
                },
                {
                    component: 'TheHeaderAboutAsync',
                    mountSelector: '#header-about',
                    allowRender: false,
                    async: true,
                    relation: 'header-about-toggle'
                },
                {
                    component: 'TheHeaderSearchAsync',
                    mountSelector: '#header-search',
                    allowRender: false,
                    async: true,
                    relation: 'header-search'
                },
                {
                    component: 'TheHeaderCallbackAsync',
                    mountSelector: '#header-callback',
                    allowRender: false,
                    async: true,
                    relation: 'header-callback-menu'
                },
                {
                    component: 'TheHeaderProfileAsync',
                    mountSelector: '#header-profile',
                    allowRender: false,
                    async: true,
                    relation: 'header-profile'
                },
                {
                    component: 'TheHeaderCheckPrintAsync',
                    mountSelector: '#header-check-print',
                    allowRender: false,
                    async: false,
                    relation: 'popup-print-receipt'
                },
                {
                    component: 'TheCartHeaderDropdown',
                    mountSelector: '#cart-header-dropdown',
                    allowRender: false,
                    async: false
                },
                {
                    component: 'TheCartModalsAddedProduct',
                    mountSelector: '#cart-modals-add-product',
                    allowRender: false,
                    async: false,
                },
                {
                    component: 'TheHeaderSchedule',
                    mountSelector: '#header-schedule',
                    allowRender: false,
                    async: false,
                }
            ] as ComponentProperties[],
        }
    },
    mounted() {
        this.componentsList.forEach(el => {
            const mountPointElement = document.querySelector<HTMLElement>(el.mountSelector)

            if (!mountPointElement) return void 0

            if (el.async && el.relation) {
                this.registerRenderFunction(el)
            } else {
                el.allowRender = true
            }
        })
    },
    setup () {
        const { currentCity, links } = store.refs

        onBeforeMount(async () => {
            if (links.value) return
            try {
                const response = await fetch('/module/cities/ajax?ajax=1', { headers: commonXHRHeaders })
                const json = await response.json()

                links.value = json.links as CommonLinksList
                currentCity.value = json.data as City
            } catch (error) {
                console.error(error)
            }
        })

        return { currentCity, links }
    },
    methods: {
        registerRenderFunction(el: ComponentProperties) {
            const callback = (e: Event) => {
                const target = e.target as HTMLElement

                if (
                    (!target.classList.contains('dropdown-entry') && !target.classList.contains('manual-entry') )
                    || target.dataset.relation !== el.relation
                ) return void 0

                el.allowRender = true
                window.removeEventListener('click', callback)
                if (el.mountEvent) {
                    window.removeEventListener(el.mountEvent || '', lazyMountCallback)
                }
            }

            window.addEventListener('click', callback)

            const lazyMountCallback = () => {
                el.allowRender = true
                window.removeEventListener('click', callback)
                window.removeEventListener(el.mountEvent || '', lazyMountCallback)
            }

            if (el.mountEvent) {
                window.addEventListener(el.mountEvent, lazyMountCallback)
            }
        },
    }
})
</script>
