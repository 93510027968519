window.addEventListener('DOMContentLoaded', () => {
    const marquizElements = document.querySelectorAll('.marquiz')
    if (marquizElements.length === 0) {
        return
    }

    marquizElements.forEach((marquizElement) => {
        marquizElement.addEventListener('click', async (e) => {
            e.preventDefault()
            const element = e.target as HTMLElement

            let modalId = element.dataset.modalId
            const marquizLink = element.dataset.link

            if (!modalId) {
                return
            }

            if (window.Marquiz) {
                const ourDomainRegex = /^(?:https?:\/\/)?(?:quiz\.express-office|quiz\.home24)\..*$/i
                if (marquizLink && ourDomainRegex.test(marquizLink)) {
                    try {
                        const quizData = await fetch(`https://quiz.provider.marquiz.ru/v1/quizzes/findByProjectDomain?projectKey=${modalId}&projectDomain=quiz.express-office.ru`)
                        const quizDataJson = await quizData.json()

                        if (quizDataJson?.id?.length) {
                            modalId = quizDataJson.id
                        }
                    } catch (error) {}
                }

                window.Marquiz.showModal(modalId)
            } else if (marquizLink) {
                window.open(marquizLink, '_blank')
            }
        })
    })

    const marquizCloseIcon = document.querySelector('.mobile-quiz__close')

    if (marquizCloseIcon) {
        const marquizBlock = document.querySelector('.mobile-quiz')
        marquizCloseIcon.addEventListener('click', () => {
            marquizBlock?.classList.remove('mobile-quiz--active')
        })
    }
});


