class AnimatedLogo {
    public name: string
    public uid: string
    private root: SVGElement | null
    private elements: NodeListOf<Element> | undefined
    private delayTiming: number // время на показ лого без трансформации
    private animationTiming: number // время на анимирование одной линии в SVG
    private relaxTiming: number // время задержки перед повторной анимации (нужно для более приятной глазу плавности)

    constructor(container: Element, name: string) {
        this.name = name
        this.uid = `_${Math.random().toString(36).substring(2, 5 + 2)}`
        this.root = container.querySelector<SVGElement>(`[data-id="${name}"]`)
        this.elements = this.root?.querySelectorAll('.animated-logo-line')
        this.delayTiming = 1500
        this.animationTiming = 100
        this.relaxTiming = 400
    }

    private getTotalAnimationTime() {
        /*
        если нужно переопределить скорость аниации
        для svg где очень много элементов path или
        наоборот слишком мало, то лучше сделать здесь
        this.elements?.length > n ? this.animationTiming : snus
        а если надо изменять delayTiming или relaxTiming, то 
        можно по аналогии с этой function
        */
        if (this.elements) {
            const elemsNum = this.elements?.length            
            return elemsNum * this.animationTiming
        }

        return NaN
    }

    public show() {
        if (!this.root) return
        this.root.classList.add('is-open')

        this.elements?.forEach((element, key) => {                
            setTimeout(() => element.classList.add('is-open'), (key + 1) * this.animationTiming)
        })

        setTimeout(() => {            
            this.hide()
            document.dispatchEvent(new Event(`show:${this.name}:${this.uid}`))
        }, this.getTotalAnimationTime() + this.delayTiming + this.relaxTiming)

    }

    public hide() {

        this.elements?.forEach((element, key) => {

            setTimeout(() => element.classList.remove('is-open'), (key + 1) * this.animationTiming)
        })

        setTimeout(() => {
            if (this.root) this.root.classList.remove('is-open')
            document.dispatchEvent(new Event(`hide:${this.name}:${this.uid}`))
        }, this.getTotalAnimationTime()  + this.delayTiming + this.relaxTiming)
    }
}

const initAnimation = () => {
    const logoContainers = document.querySelectorAll('.animated-logo-container')
    const logoSVGList = document.querySelectorAll<HTMLElement>(`.animated-logo-container svg:not(.bg)`)

    let logoSVGElems = [] as Array<string>

    logoSVGList?.forEach((el) => {
        const logoSVGItem = el.dataset.id as string
        logoSVGElems.push(logoSVGItem) 
    });
        
    if (logoContainers.length) {
        logoContainers.forEach((container) => {
            let SVGArray = [] as Array<AnimatedLogo>

            logoSVGElems.forEach((SVGElem) => {
                SVGArray.push(new AnimatedLogo(container, SVGElem)) 
            })

            SVGArray[0].show()
            SVGArray.forEach((el,index) => {
                if (SVGArray.length - 1 !== index) document.addEventListener(`hide:${el.name}:${el.uid}`, () => SVGArray[index + 1].show())
                if (SVGArray.length - 1 === index) document.addEventListener(`hide:${el.name}:${el.uid}`, () => SVGArray[0].show())
            })
        })
    }
}

initAnimation()
