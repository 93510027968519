<script setup lang="ts">
import {AdvertData} from "~vue/banner/types";
import {computed, ref} from "vue";

withDefaults(
    defineProps<{
        data: AdvertData,
        position?: 'top'|'bottom'
    }>(),
    {
        position: 'bottom'
    }
)

const popupIsShow = ref<boolean>(false)
</script>

<template>
    <div class="advert-popup">
        <button
            class="advert-popup__button"
            type="button"
            @click="popupIsShow = !popupIsShow"
        >
            <span class="advert-popup__text">реклама</span>
            <span class="advert-popup__dots">
                <span />
            </span>
        </button>

        <div
            v-if="popupIsShow"
            :class="{
                'advert-popup__content': true,
                [`advert-popup__content--${position}`]: true,
            }"
        >
            <div class="advert-popup__content-text">
                <p class="mb-2"><b>Помогаем нашим партнёрам рассказать об их товарах.</b></p>
                <p class="mb-2">Рекламодатель: {{ data.address }}</p>
                <p>Erid: {{ data.erid }}</p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.advert-popup {
    @apply absolute
        top-10
        right-10
        z-10;

    &__button {
        @apply flex
            items-center
            opacity-50
            cursor-pointer
            bg-white
            pl-2
            pt-px
            pb-0.5
            pr-2
            rounded-full;
    }

    &__text {
        @apply mr-1
            font-medium
            capitalize
            text-black
            text-caption;
    }

    &__dots {
        @apply flex
            flex-col
            px-1
            gap-px
            justify-between
            h-full;


        &::before,
        &::after,
        span {
            content: "";

            @apply block
                rounded-full
                w-0.5
                h-0.5
                bg-black;
        }
    }

    &__content {
        @apply absolute
            right-0
            z-10
            bg-white
            text-caption
            w-max
            max-w-[calc(100vw-2rem)]
            p-3
            rounded-xl
            shadow-base-2;

        &--bottom {
            @apply top-[1.8rem];

            &::after {
                @apply top-0
                    -translate-y-1/2;
            }
        }

        &--top {
            @apply -top-[0.6rem]
                -translate-y-full;

            &::after {
                @apply bottom-0
                    translate-y-1/2;
            }
        }

        &::after {
            content: "";

            @apply absolute
                right-8
                h-[0.6rem]
                w-[0.6rem]
                rotate-45
                bg-white
                origin-center
                border-t-white;
        }
    }

    &__content-text {
        @apply relative
            text-shuttle-gray
            z-10;

        &:deep(b) {
            @apply text-black
                font-medium;
        }
    }
}
</style>
