export interface ImageSourceAttributes {
    media: string
    srcset: string
    type?: string
}

export interface Image {
    src: string
    alt?: string
    title?: string
    mime?: string
    sizes?: {
        w: number
        h: number
    }
    sources?: ImageSourceAttributes[]
}

export enum BannerTypesEnum {
    MENU_RIGHT = 'menu_right',
    MENU_BOTTOM = 'menu_bottom',
    MAIN_PAGE_FULL = 'main_page_full',
    MAIN_PAGE_LEFT = 'main_page_left',
    MAIN_PAGE_RIGHT = 'main_page_right',
    HEADER = 'header',
}

export interface BannerImages {
    largexl: Image
    small?: Image
    bg?: Image
}

export interface AdvertData {
    address: string
    erid: string
}

export interface Banner {
    id: string
    type: BannerTypesEnum
    images: BannerImages
    title?: string
    button_text?: string
    advert?: AdvertData
    url?: string
    promo_code?: string,
    promotion_name: string
}
